@import url(//db.onlinewebfonts.com/c/2167e76f00e569cc11b3665679996380?family=Futura+Light);
@import url('https://fonts.cdnfonts.com/css/didot-2');
@import url('https://fonts.cdnfonts.com/css/caveat');
@import url('https://fonts.cdnfonts.com/css/cream-peach');
@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/cursive');
@import url('https://fonts.cdnfonts.com/css/Roboto');



.ql-container.ql-snow {
    border: none !important;

}

.ql-editor {
    overflow-y: auto !important;
}

.ql-toolbar.ql-snow {
    /* position: fixed; */
    /* width: 100%; */


    /*background-color: bisque;
    border: none !important;
    border-left: 1px solid aliceblue !important;
    border-right: 1px solid aliceblue !important;*/

}

/* TODO how to do it right without !important ? */
.ql-snow .ql-editor pre.ql-syntax {
    background-color: #f0f0f0 !important;
    color: #23241f !important;
    overflow: visible !important;
}

.css-honxw6 {
    width: 8px !important;
    height: 8px !important;
}

.css-jn69v9 {
    width: 8px !important;
    height: 8px !important;
}

.ql-snow {
    .ql-picker {
        &.ql-size {

            .ql-picker-label,
            .ql-picker-item {
                &::before {
                    content: attr(data-value) !important;
                }
            }
        }
    }
}

.tag-item {
    background-color: #d4d5d6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
}

.tag-item>.button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dori {
    /* list-style-type: circle; */
    /* U+1F582 1F5F8 */
    list-style-type: "\1F5F8";
    /* list-style-image: url('**your icon path **  icon_sample.jpg'); */

}

/* div:has(.uniqueChild) {
        width: 100%
    } */



@tailwind base;

@tailwind components;

@tailwind utilities;

/* @layer base {
   
.RichEditor-editor .public-DraftEditor-content h1{
    font-size: 2em!important;
    font-weight: bolder!important;
}

.RichEditor-editor .public-DraftEditor-content h2{
    font-weight: bolder!important;
    font-size: 1.5em!important;
    }
    
    .RichEditor-editor .public-DraftEditor-content h3{
    font-weight: bolder!important;
    font-size: 1.7em!important;
    
    }
    
    .RichEditor-editor .public-DraftEditor-content h4{
    font-size: 1em!important;
    font-weight: bolder!important;
    }
    
    .RichEditor-editor .public-DraftEditor-content h5{
    font-size: .83em!important;
    font-weight: bolder!important;
    }
    
    .RichEditor-editor .public-DraftEditor-content h6{
    font-size: .67em!important;
    font-weight: bolder!important;
    }
} */